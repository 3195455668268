import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from 'aws-amplify';
import { from, Observable } from 'rxjs';
import { SelfInfo, UserInfo } from 'src/types/main.types';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  constructor(private http: HttpClient) { }

  public getThumbnails(): Observable<any> {
    return from(API.get("main-neknom-api", `/thumbnails`, {}));
  }

  public getThumbnailsBulk(forceRefresh: boolean): Observable<any> {
    return from(API.get("main-neknom-api", `/thumbnails/bulk${forceRefresh ? "?refresh=true" : ""}`, {}));
  }

  public getVideoUrl(year: string, name: string): Observable<any> {
    return from(API.get("main-neknom-api", `/video?year=${year}&initials=${name}`, {}));
  }

  public getAllUsersDetails(): Observable<UserInfo[]> {
    return from(API.get("main-neknom-api", `/users`, {}));
  }

  public getUserDetails(): Observable<SelfInfo> {
    return from(API.get("main-neknom-api", `/user`, {})); 
  }

  public resetYear(year: string): Observable<any> {
    return from(API.post("main-neknom-api", `/reset`, {
      body: {
        year
      }
    })); 
  }

  public downloadZip(url: string): Observable<any> {
    return this.http.get(url, {
      responseType: 'arraybuffer'
    });
  }

  public getUploadUrls(year: string, name: string): Observable<{ video: string; thumbnail: string }> {
    return from(API.get("main-neknom-api", `/upload?year=${year}&initials=${name}`, {}));
  }

  public postNomination(id: string, isOverride: boolean): Observable<{ message: string }> {
    return from(API.post("main-neknom-api", `/nomination`, {
      body: {
        nomination: id,
        override: isOverride
      }
    }));
  }

  public uploadFileToSignedURL(url: string, file: File): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': file.type });
    return this.http.put<any>(url, file, {
      headers,
      reportProgress: true,
      observe: 'events'
    });
}
}

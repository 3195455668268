import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Auth } from 'aws-amplify';

enum LoginState {
  LOGIN = "LOGIN",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  CHANGE_PASSWORD = "CHANGE_PASSWORD",
  SET_PASSWORD = "SET_PASSWORD"
}

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public isLoading = false;
  public currentState = LoginState.LOGIN;
  public errorMessage = "";

  public email = "";
  public loginPassword = "";
  public newPassword1 = "";
  public newPassword2 = "";
  public verificationCode = "";

  constructor(private router: Router) { }

  ngOnInit(): void {
    Auth.signOut().then(() => console.log("Session reset"));
  }

  changeLoginState(value: string) {
    this.clearPasswords();
    this.errorMessage = "";

    switch (value) {
      case "LOGIN":
        this.currentState = LoginState.LOGIN;
        return;
      case "FORGOT_PASSWORD":
        this.currentState = LoginState.FORGOT_PASSWORD;
        return;
      case "CHANGE_PASSWORD":
        this.currentState = LoginState.CHANGE_PASSWORD;
        return;
      case "SET_PASSWORD":
        this.currentState = LoginState.SET_PASSWORD;
        return;
      default:
        console.warn("Unknown state");
    }
  }

  async login() {
    this.errorMessage = "";
    this.isLoading = true;
    try {
      const res = await Auth.signIn(this.email.trim(), this.loginPassword);
      this.isLoading = false;
      if (res.challengeName === "NEW_PASSWORD_REQUIRED") {
        this.changeLoginState(LoginState.SET_PASSWORD);
      } else {
        await this.router.navigate(['/home'], { queryParamsHandling: "preserve" });
      }
      
    } catch (err: any) {
      this.isLoading = false;
      console.log(err);
      this.errorMessage = err.message;
    }
    this.clearPasswords();
  }

  async setNewPassword() {
    this.errorMessage = "";
    if (this.newPassword1 !== this.newPassword2) {
      this.errorMessage = "Passwords don't match";
      return;
    }

    this.isLoading = true;
    try {
      const user = await Auth.signIn(this.email.trim(), this.loginPassword);
      console.log(user);
      await Auth.completeNewPassword(user, this.newPassword1, {});
      this.isLoading = false;
      this.changeLoginState(LoginState.LOGIN);
      this.errorMessage = "Password updated";
    } catch (err: any) {
      this.isLoading = false;
      console.log(err);
      this.errorMessage = err.message;
    }
  }

  async sendAuthCode() {
    this.errorMessage = "";
    this.isLoading = true;
    try {
      await Auth.forgotPassword(this.email.trim());
      this.isLoading = false;
      this.changeLoginState(LoginState.CHANGE_PASSWORD);
    } catch (err: any) {
      this.isLoading = false;
      console.log(err);
      this.errorMessage = err.message;
    }
  }

  async resetPassword() {
    this.errorMessage = "";
    if (this.newPassword1 !== this.newPassword2) {
      this.errorMessage = "Passwords don't match";
      return;
    }

    this.isLoading = true;
    try {
      await Auth.forgotPasswordSubmit(this.email.trim(), this.verificationCode, this.newPassword1);
      this.isLoading = false;
      this.changeLoginState(LoginState.LOGIN);
      this.errorMessage = "Password updated";
    } catch (err: any) {
      this.isLoading = false;
      console.log(err);
      this.errorMessage = err.message;
    }
  }

  clearPasswords() {
    this.loginPassword = "";
    this.newPassword1 = "";
    this.newPassword2 = "";
    this.verificationCode = "";
  }
}

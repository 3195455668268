export const environment = {
  version: "1.0.0",
  envName: "prod",
  production: true,
  amplifyConfig: {
    Auth: {
      identityPoolId: 'eu-west-2:f358e6cb-cfe4-4e65-a78d-502f78c62f50',
      region: 'eu-west-2',
      userPoolId: 'eu-west-2_ntP0I3Nbs',
      userPoolWebClientId: '7onsubfo1qmouvinnsgb13tkj7',
      mandatorySignIn: true,
      authenticationFlowType: 'USER_SRP_AUTH',
    },
    API: {
      endpoints: [
        {
          name: 'main-neknom-api',
          region: 'eu-west-2',
          endpoint: 'https://z340jp3zsj.execute-api.eu-west-2.amazonaws.com/prod'
        }
      ]
    }
  }
};

import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer } from '@angular/platform-browser';
import MediaInfoFactory from 'mediainfo.js'
import { MediaInfo, ReadChunkFunc, Result } from 'mediainfo.js/dist/types';
import { combineLatest, forkJoin, Observable, Observer, of, zip } from 'rxjs';
import { AppService } from 'src/app/app.service';
import { SelfInfo, UserInfo } from 'src/types/main.types';

@Component({
  selector: 'app-uploadmodal',
  templateUrl: './uploadmodal.component.html',
  styleUrls: ['./uploadmodal.component.scss']
})
export class UploadmodalComponent implements OnInit {

  public uploadedVideoURL: any = "";
  public uploadedVideo: File | null = null;
  public position = 0;
  public videoPlayer: ElementRef<HTMLVideoElement> | undefined;
  public canvas: ElementRef<HTMLCanvasElement> | undefined;
  private rotation = 0;

  public screenshotHeight = 144;
  public screenshotWidth = 256;

  public users: UserInfo[] = [];
  public selfInfo: SelfInfo;
  public selectedYear: string;
  public selectedName: string;
  public selectedUser: UserInfo | undefined;
  public remainingUsers = true;
  public videoUploadUrl = "";
  public thumbnailUploadUrl = "";
  public nominee = "";

  public videoUploadPercentage = 0;
  public imageUploadPercentage = 0;
  public isUploading = false;
  public isNominating = false;
  public uploadingMessage = "";
  public errorMessage = "";

  public isOverride = false;

  @ViewChild("videoPlayer", { static: false }) set vContent(content: ElementRef<HTMLVideoElement>) {
    if (content) {
      this.videoPlayer = content;
    }
  }
  @ViewChild("canvas", { static: false }) set cContent(content: ElementRef<HTMLCanvasElement>) {
    if (content) {
      this.canvas = content;
    }
  }

  constructor(
    private _sanitizer: DomSanitizer,
    private appService: AppService,
    private matDialogRef: MatDialogRef<UploadmodalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { users: UserInfo[], year: string, name: string, selfInfo: SelfInfo, isOverride: boolean }
  ) {
    this.selfInfo = data.selfInfo;
    this.users = data.users.filter((user) => user.id !== this.selfInfo.id);
    this.selectedName = data.name;
    this.selectedUser = data.users?.find((user) => user.id === this.selectedName);
    this.selectedYear = data.year;
    this.isOverride = data.isOverride;

    const remainingUsers = this.users.filter((user) => !user.completed);
    this.remainingUsers = !!remainingUsers.length;
  }

  ngOnInit(): void {
    this.appService.getUploadUrls(this.selectedYear, this.selectedName).subscribe({
      next: (res) => {
        this.videoUploadUrl = res.video;
        this.thumbnailUploadUrl = res.thumbnail;
      }
    }
    )
  }

  public uploadFileFromEvent(e: any) {
    const files = e.target.files as File[] || [];
    this.fileUpload(files);
  }

  public fileUpload(files: File[]) {

    if (!files || !files.length) {
      return;
    }

    if (!files[0].type.includes("video/")) {
      // Error
      return;
    }

    this.uploadedVideo = files[0];
    if (this.uploadedVideoURL) {
      URL.revokeObjectURL(this.uploadedVideoURL);
    }

    this.uploadedVideoURL = this._sanitizer.bypassSecurityTrustResourceUrl(URL.createObjectURL(files[0]));
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.load();
      this.videoPlayer.nativeElement.currentTime = 0;
      this.position = 0;
    }

    MediaInfoFactory({ format: 'JSON' }, (mediainfo: MediaInfo) => {
      this.getMetadata(mediainfo, files[0]).then((info) => {
        const tracks = info?.media?.track ?? [];
        const videoData = tracks.find((tr: any) => tr['@type'] === "Video");
        if (!videoData) {
          // Error
          console.warn("Unable to find metadata");
          return;
        }
        this.rotation = 0;
        if (videoData.Rotation) {
          this.rotation = parseInt(videoData.Rotation);
        }
        console.log("rotation", this.rotation);
      })
    });
  }

  refreshFile() {
    this.uploadedVideo = null;
    this.uploadedVideoURL = "";
    this.position = 0;
  }

  updateVideoPosition(value: number) {
    this.position = value;
    if (this.videoPlayer) {
      this.videoPlayer.nativeElement.currentTime = this.videoPlayer.nativeElement.duration * (this.position / 100);
    }
  }

  extractScreenshot() {
    return new Observable((observer: Observer<File>) => {
      if (!this.videoPlayer || !this.canvas) {
        observer.error("No video player")
        observer.complete();
        return;
      }
      const video = this.videoPlayer.nativeElement;
      const canvas = this.canvas.nativeElement;

      canvas.width = this.screenshotWidth;
      canvas.height = this.screenshotHeight;

      // Height is the deterministic factor as it must fit, width is variable as can be filled in by black
      const scaleFactor = this.screenshotHeight / video.videoHeight;
      const newVideoWidth = video.videoWidth * scaleFactor;
      const newVideoHeight = video.videoHeight * scaleFactor;

      // Transform needs to center the image
      const xShift = this.rotation === 90 || this.rotation === 270 ? -(this.screenshotWidth / 2) - (newVideoWidth / 2) : (this.screenshotWidth / 2) - (newVideoWidth / 2);
      console.log("old dimensions", video.videoWidth, video.videoHeight);
      console.log("new dimensions", newVideoWidth, newVideoHeight);

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.fillStyle = "black";
        ctx.fillRect(0, 0, this.screenshotWidth, this.screenshotHeight);
        if (this.rotation === 90 || this.rotation === 270) {
          ctx.rotate(this.rotation * Math.PI / 180);
          ctx.drawImage(video, 0, xShift, newVideoHeight, newVideoWidth);
        } else {
          ctx.drawImage(video, xShift, 0, newVideoWidth, newVideoHeight);
        }
        canvas.toBlob((blob) => {
          if (blob) {
            observer.next(new File([blob], `${this.selectedName}.png`, { type: "image/png" }));
            observer.complete();
            return;
          } else {
            observer.error("Error converting image to file");
            observer.complete();
            return;
          }
        })
      } else {
        observer.error("Context not found");
        observer.complete();
        return;
      }
    });
  }

  getMetadata(mediainfo: MediaInfo, file: File): Promise<any> {
    return new Promise<any>((resolve) => {
      const getSize = () => file.size
      const readChunk: ReadChunkFunc = (chunkSize, offset) =>
        new Promise((resolve, reject) => {

          const reader = new FileReader()

          reader.onload = (event: any) => {
            if (event && event.target.error) {
              reject(event.target.error)
            }
            resolve(new Uint8Array(event.target.result as ArrayBuffer))
          }
          reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize))
        })
      const p = <Promise<Result>>mediainfo.analyzeData(getSize, readChunk)
      p
        .then((result: any) => resolve(JSON.parse(result)))
        .catch(() =>
          resolve("Can't get media information")
        )
    })
  }

  uploadProcess() {
    this.uploadingMessage = "";
    if (!this.uploadedVideoURL) {
      return;
    }
    this.isUploading = true;
    this.uploadingMessage = "Generating thumbnail";
    this.extractScreenshot().subscribe({
      next: (img) => {
        if (!this.uploadedVideo) {
          throw new Error("No video to upload");
        }
        const imgUpload = this.appService.uploadFileToSignedURL(this.thumbnailUploadUrl, img);
        const videoUpload = this.appService.uploadFileToSignedURL(this.videoUploadUrl, this.uploadedVideo);
        this.uploadingMessage = `Uploading video - 0%`;
        combineLatest([imgUpload, videoUpload]).subscribe({
          next: ([imgStatus, videoStatus]) => {
            if (imgStatus.loaded && imgStatus.total) {
              this.imageUploadPercentage = imgStatus.loaded / imgStatus.total;
            }
            if (videoStatus.loaded && videoStatus.total) {
              this.videoUploadPercentage = videoStatus.loaded / videoStatus.total;
              this.uploadingMessage = `Uploading video - ${Math.ceil(this.videoUploadPercentage * 100)}%`;
              console.log(this.uploadingMessage);
            }
            if (this.videoUploadPercentage === 1 && this.imageUploadPercentage === 1) {
              this.postNomination();
            }
          },
          error: (err) => {
            console.warn(err);
            this.isUploading = false;
          }
        })
      },
      error: (err) => console.warn(err)
    });
  }

  isUploadDisabled() {
    if (this.isOverride) {
      return false;
    }
    return this.isUploading || this.isNominating || !this.uploadedVideoURL || (this.remainingUsers && !this.nominee);
  }

  postNomination() {
    if (this.isNominating) {
      return;
    }
    this.isNominating = true;
    this.isUploading = true;
    this.uploadingMessage = "Submitting nomination...";
    this.errorMessage = "";
    this.appService.postNomination(this.nominee, this.isOverride).subscribe({
      next: () => {
        this.uploadingMessage = "";
        this.isUploading = false;
        this.isNominating = false;
        this.matDialogRef.close(true);
      },
      error: (err) => {
        this.isUploading = false;
        this.isNominating = false;
        console.warn(err);
        this.errorMessage = err?.response?.message || "Unknown error";
      }
    })
  }
}

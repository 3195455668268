<div class="base">
    <div class="title-container">
        <h1>Christmas Neknom Archives</h1>
        <div *ngIf="countdownString !== ''" [@tableFadeIn]>
            <h3 *ngIf="selfInfo">Welcome back {{selfInfo.name.split(' ')[0]}}</h3>
            <h3 [ngStyle]="{'color': isChristmas ? 'red' : 'black'}">{{countdownString}}</h3>
            <p *ngIf="currentNomineeSentence">{{ currentNomineeSentence }}</p>
        </div>
        <div class="button-container">
            <button class="action-button" mat-raised-button (click)="navigateToUpload()" [disabled]="isUploadDisabled()">Upload Video</button>
            <button class="action-button" mat-raised-button (click)="logout()">Logout</button>
        </div>
    </div>
    <mat-spinner class="spinner-center" *ngIf="isLoading"></mat-spinner>
    <div class="table-section" *ngIf="!isLoading" [@tableFadeIn]>
        <table>
            <thead>
                <tr>
                    <th class="blank"> </th>
                    <th *ngFor="let name of columnTitles">{{initialMap[name] || 'Unknown'}}</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let year of rowTitles">
                    <td (click)="selfInfo?.isAdmin && ctrlPressed ? confirmResetYear(year) : null">{{year}}</td>
                    <td *ngFor="let name of columnTitles">
                        <img class="image-tile"
                            [ngClass]="{'clickable': thumbnails[year][name], 'hover-shadow': thumbnails[year][name]}"
                            [src]="thumbnails[year][name] || './assets/1200px-Question_Mark.png'"
                            (click)="selfInfo?.isAdmin && ctrlPressed ? openUploadModal(year, name, true) : goToVideoUrl(year, name)" />
                    </td>
                </tr>
            </tbody>
        </table>
        <mat-slide-toggle [(ngModel)]="isOptimised" (change)="optimiseTable($event.checked)">Optimise</mat-slide-toggle>
    </div>
</div>
<div *ngIf="isOpen" [@videoFadeIn] [@videoFadeOut]>
    <div class="background-cover"></div>
    <div class="full-height">
        <div class="close-background" (click)="closeModal()"></div>
        <span class="icons">
            <span class="copy" (click)="copyUrl()">
                <svg-icon src="assets/clipboard.svg"></svg-icon>
            </span>
            <span class="share" (click)="shareVideo()">
                <svg-icon src="assets/share-icon.svg"></svg-icon>
            </span>
            <span class="close" (click)="closeModal()">&times;</span>
        </span>
        <video *ngIf="videoUrl !== ''" autoplay controls>
            <source [src]="videoUrl" type="video/mp4">
        </video>
    </div>
</div>
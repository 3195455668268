<div class="upload-modal">
    <div class="upload-container">
        <div class="upload-file-area" *ngIf="!uploadedVideo" appUploadFile (onFileDropped)="fileUpload($event)">Drop your video here</div>
        <div class="button-container" *ngIf="!uploadedVideo">
            <button mat-raised-button class="action-button" (click)="fileInput.click()">Choose File</button>
            <input type="file" accept= "video/*" class="file-input" (change)="uploadFileFromEvent($event)" #fileInput>
            <button mat-raised-button mat-dialog-close class="action-button">Cancel</button>
        </div>
        <div class="file-info-container" *ngIf="uploadedVideo">
            <p>
                <b>Selected file:</b><br>
                {{uploadedVideo.name}}<br>
                ({{uploadedVideo.size / 1000 / 1000 | number}} MB)
            </p>
            <button mat-raised-button class="action-button" (click)="refreshFile()">Change File</button>
        </div>
    </div>
    <div class="video-container" *ngIf="uploadedVideoURL">
        <div class="video-canvas">
            <video disablePictureInPicture="true" #videoPlayer>
                <source [src]="uploadedVideoURL">
                Your browser does not support HTML5 video
            </video>
            <canvas #canvas [width]="screenshotWidth" [height]="screenshotHeight"></canvas>
        </div>
        <p>Drag slider to select image for thumbnail</p>
        <mat-slider min="0" max="100" step="0.1" [value]="position" (input)="updateVideoPosition($event.value ?? 0)"></mat-slider>
    </div>
    <div class="upload-container" *ngIf="uploadedVideoURL">
        <span *ngIf="remainingUsers && !isOverride" class="user-select">
            <p>Who would you like to nominate?</p>
            <select [(ngModel)]="nominee" [disabled]="isUploading || isNominating">
                <option value="" disabled selected hidden>Select...</option>
                <option *ngFor="let user of users" [disabled]="!user.canNominate || user.completed" [value]="user.id">{{user.name}}</option>
            </select>
        </span>
        <span *ngIf="!remainingUsers && !isOverride" class="user-select">
            <p>There is no one left to nominate!</p>
        </span>
        <div class="button-container">
            <!-- <button mat-raised-button class="action-button" [disabled]="!uploadedVideoURL" (click)="extractScreenshot()">Take Screenshot</button> -->
            <button mat-raised-button class="action-button" [disabled]="isUploadDisabled()" (click)="uploadProcess()">Upload</button>
            <button mat-raised-button mat-dialog-close class="action-button" [disabled]="isUploading || isNominating">Cancel</button>
        </div>
        <div class="uploading-status" [ngStyle]="{ 'visibility': isUploading ? 'visible' : 'hidden' }">
            <mat-spinner [diameter]="20"></mat-spinner>
            <p class="info-text">{{ uploadingMessage }}</p>
        </div>
        <p class="error-text" *ngIf="!isUploading && errorMessage">{{ errorMessage }}</p>
    </div>
    <p *ngIf="isOverride"> Admin update for {{ selectedUser?.display || selectedName }} ({{ selectedYear }})</p>
</div>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Auth } from 'aws-amplify';
import { MatDialog } from "@angular/material/dialog";
import { animate, style, transition, trigger } from "@angular/animations";
import { Clipboard } from '@angular/cdk/clipboard';
import * as JSZip from 'jszip';

import { AppService } from 'src/app/app.service';
import { SelfInfo, ThumbnailObject, UserInfo } from '../../../types/main.types';
import { forkJoin, interval, Subscription } from 'rxjs';
import { UploadmodalComponent } from 'src/app/components/uploadmodal/uploadmodal.component';
import { AlertModalComponent } from 'src/app/components/alert-modal/alert-modal.component';
import { ConfirmModalComponent } from 'src/app/components/confirm-modal/confirm-modal.component';
import { environment } from 'src/environments/environment';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  host: {
    '(document:keydown)': 'handleKeyboardEvent($event, true)',
    '(document:keyup)': 'handleKeyboardEvent($event, false)'
  },
  animations: [
    trigger('tableFadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(500, style({ opacity: 1 }))
      ])
    ]),
    trigger('videoFadeIn', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate(200, style({ opacity: 1 }))
      ])
    ]),
    trigger('videoFadeOut', [
      transition(':leave', [
        animate(200, style({ opacity: 0 }))
      ])
    ]),
  ]
})
export class MainComponent implements OnInit, OnDestroy {

  public rowTitles: string[] = [];
  public columnTitles: string[] = [];
  public thumbnails: ThumbnailObject = {};
  public initialMap: { [id: string]: string } = {};
  public users: UserInfo[] = [];
  public selfInfo: SelfInfo | null = null;
  public currentNomineeId = "";
  public currentNomineeSentence = "";

  public isLoading = false;
  public isOptimised = false;

  public videoUrl = "";
  public isOpen = false;
  public ctrlPressed = false;

  public countdownString = "";
  public isChristmas = false;
  public subscription = new Subscription();

  public christmasDate = 25;
  public currentParams: { [key: string]: string } = {};

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private appService: AppService,
    private _sanitizer: DomSanitizer,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private clipboard: Clipboard
  ) {
    if (environment.envName !== "prod") {
      this.christmasDate = 25;
    }
    this.subscription.add(this.route.queryParams.subscribe((params) => {
      this.currentParams = { ...params };
      if (params['v'] && String(params['v']).split('-').length === 2) {
        const link = String(params['v']);
        const [id, year] = link.split('-');
        this.loadVideo(year, id);
      } else if (params['upload'] && String(params['upload']) === "true") {
        const userSub = this.appService.getUserDetails();
        const allUsersSub = this.appService.getAllUsersDetails();
        forkJoin([userSub, allUsersSub]).subscribe({
          next: ([userInfo, allUsers]) => {
            const year = new Date().getFullYear().toString()
            this.users = allUsers;
            this.checkBeforeUpload(year, userInfo.id, false);
          }
        })
      } else {
        this.closeModal();
      }
    }));
  }

  ngOnInit(): void {
    this.isLoading = true;
    // this.appService.getThumbnails().subscribe({
    //   next: (res: ThumbnailObject) => this.processThumbails(res),
    //   error: (err) => { console.warn(err); this.isLoading = false; }
    // });
    this.appService.getUserDetails().subscribe({
      next: (res) => {
        this.selfInfo = res;
        this.loadUsers(false);
      },
      error: (err) => console.warn(err),
      complete: () => {
        this.generateCountdownString();
        this.subscription.add(interval(1000).subscribe(() => this.generateCountdownString()));
      }
    })
  }

  loadUsers(forceRefresh: boolean) {
    this.appService.getAllUsersDetails().subscribe({
      next: (res: UserInfo[]) => {
        this.users = res;
        this.initialMap = {};
        res.forEach(user => this.initialMap[user.id] = user.display);
        this.columnTitles = Object.keys(this.initialMap).sort((a, b) => a.localeCompare(b));
        this.calculateCurrentNomineeSentence(res);

        this.appService.getThumbnailsBulk(forceRefresh).subscribe({
          next: (res: { [year: string]: string }) => this.processThumbnailsBulk(res),
          error: (err) => { console.warn(err); this.isLoading = false; }
        });
      },
      error: (err) => { console.warn(err); this.isLoading = false; }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  processThumbails(thumbnails: ThumbnailObject) {
    this.rowTitles = Object.keys(thumbnails).sort((a, b) => a.localeCompare(b));
    this.thumbnails = thumbnails;
    this.isLoading = false;
  }

  async processThumbnailsBulk(zipObject: { [year: string]: string }) {
    const years = Object.keys(zipObject);
    const thumbnails: ThumbnailObject = {};
    years.map(year => thumbnails[year] = {});
    const currentYear = new Date().getFullYear();
    if (!thumbnails[currentYear]) {
      thumbnails[currentYear] = {};
    }
    this.rowTitles = Object.keys(thumbnails).sort((a, b) => a.localeCompare(b));
    this.thumbnails = thumbnails;
    const zipSubscriptions = years.map(year => this.appService.downloadZip(zipObject[year]));
    if (!zipSubscriptions.length) {
      this.isLoading = false;
      return;
    }
    forkJoin(zipSubscriptions).subscribe({
      next: async (results: any[]) => {
        results.map(async (data, i) => {
          const blob = new Blob([data], {
            type: 'application/zip'
          });
          const jsZip = new JSZip();
          const res = await jsZip.loadAsync(blob);
          const names = Object.keys(res.files);
          names.map(async file => {
            const base64 = await jsZip.file(file)?.async("base64");
            if (base64) {
              this.thumbnails[years[i]][file.split('.')[0]] = this._sanitizer.bypassSecurityTrustResourceUrl('data:image/png;base64,' + base64);
              this.isLoading = false;
            }
          });
        });
      }
    })
  }

  calculateCurrentNomineeSentence(users: UserInfo[]) {
    this.currentNomineeSentence = "";
    if (!this.selfInfo?.id || !users.length) {
      return;
    }
    const allFinished = users.every((user) => !!user.completed);
    if (allFinished) {
      users.sort((a, b) => new Date(a.completed).getTime() - new Date(b.completed).getTime());
      const mostRecent = users[users.length - 1].completed;
      const first = users[0].completed;
      const timeDiff = new Date(mostRecent).getTime() - new Date(first).getTime();
      const hours = Math.floor(timeDiff / 1000 / 60 / 60);
      const remainingSecs = timeDiff - (hours * 1000 * 60 * 60);
      const mins = Math.floor(remainingSecs / 1000 / 60);
      this.currentNomineeSentence = `Finished! It only took ${hours ? hours + " hours and " : ""} ${mins} minutes...`;
      return;
    }
    const sorted = users
      .filter((user) => !!user.nominated && !user.completed)
      .sort((a, b) => new Date(b.nominated).getTime() - new Date(a.nominated).getTime());
    if (!sorted.length) {
      return;
    }
    this.currentNomineeId = sorted[0].id;
    this.currentNomineeSentence = sorted[0].id === this.selfInfo.id ? "It's your turn!" : `It's ${sorted[0].display}'s turn!`;
  }

  optimiseTable(isOptimised: boolean) {
    const invalidUserIds = this.users.filter(user => !user.canNominate).map(user => user.id);
    this.columnTitles = Object.keys(this.initialMap).sort((a, b) => a.localeCompare(b));
    if (isOptimised) {
      this.columnTitles = this.columnTitles.filter(title => !invalidUserIds.includes(title));
    }
  }

  goToVideoUrl(year: string, name: string) {
    if ((!this.thumbnails || !this.thumbnails[year] || !this.thumbnails[year][name])) {
      return;
    }
    this.router.navigate(['.'], { queryParams: { v: `${name}-${year}` }, relativeTo: this.route });
  }

  async loadVideo(year: string, name: string) {
    this.appService.getVideoUrl(year, name).subscribe({
      next: (res: { url: string }) => {
        this.videoUrl = res.url;
        this.isOpen = true;
      },
      error: (err) => {
        if (err.response.status === 404) {
          this.openAlertModal("error", "Video not found!");
        } else {
          this.openAlertModal("error", err.message);
        }
      }
    });
  }

  navigateToUpload() {
    this.router.navigate(['.'], { queryParams: { upload: true }, relativeTo: this.route });
  }

  reloadVideos() {
    this.isLoading = true;
    this.appService.getThumbnailsBulk(true).subscribe({
      next: (res: { [year: string]: string }) => this.processThumbnailsBulk(res),
      error: (err) => { console.warn(err); this.isLoading = false; }
    });
  }

  checkBeforeUpload(year: string, name: string, isOverride: boolean) {
    if (this.currentNomineeId && this.selfInfo?.id !== this.currentNomineeId) {
      this.dialog.open(ConfirmModalComponent, {
        minWidth: "auto",
        minHeight: "auto",
        data: {
          title: "Are you sure?",
          message: `It's not your turn to go. Don't be a dick.`
        }
      }).afterClosed().subscribe({
        next: (confirm) => {
          if (confirm) {
            this.openUploadModal(year, name, isOverride)
          } else {
            this.closeModal();
          }
        }
      });
    } else {
      this.openUploadModal(year, name, isOverride)
    }
  }

  openUploadModal(year: string, name: string, isOverride: boolean) {
    this.dialog.open(UploadmodalComponent, {
      disableClose: true,
      minWidth: "auto",
      minHeight: "auto",
      data: {
        users: [...this.users],
        year,
        name,
        selfInfo: { ...this.selfInfo },
        isOverride
      }
    }).afterClosed().subscribe((nominated) => {
      if (nominated) {
        this.appService.getUserDetails().subscribe({
          next: (res) => {
            this.selfInfo = res;
          },
          error: (err) => console.warn(err)
        });
        this.loadUsers(true);
        this.openAlertModal("success", "Video successfully uploaded")
      }
      this.closeModal();
    });
  }

  openAlertModal(type: "success" | "error" | "info", message: string) {
    this.dialog.open(AlertModalComponent, {
      disableClose: true,
      minWidth: "auto",
      minHeight: "auto",
      data: {
        type,
        message
      }
    }).afterClosed().subscribe({
      next: () => this.closeModal()
    });
  }

  confirmResetYear(year: string) {
    this.dialog.open(ConfirmModalComponent, {
      minWidth: "auto",
      minHeight: "auto",
      data: {
        title: "Are you sure?",
        message: `Please confirm that you want to reset all videos in ${year}`
      }
    }).afterClosed().subscribe({
      next: (confirm) => {
        if (confirm) {
          this.appService.resetYear(year).subscribe({
            next: () => {
              this.reloadVideos();
              this.openAlertModal("success", `All videos for ${year} have been reset`)
            },
            error: () => this.openAlertModal("error", `Error resetting ${year}. Please try again`)
          })
        }
      }
    });
  }

  generateCountdownString() {
    const upcomingYear = new Date().getDate() > this.christmasDate && new Date().getMonth() === 11 ? new Date().getFullYear() + 1 : new Date().getFullYear();
    const isChristmas = new Date().getDate() === this.christmasDate && new Date().getMonth() === 11;
    const targetDate = isChristmas ? `${upcomingYear}-12-${this.christmasDate + 1}` : `${upcomingYear}-12-${this.christmasDate}`;
    const targetUnix = new Date(targetDate).getTime();
    let remainingSecs = Math.floor((targetUnix - Date.now()) / 1000);
    const days = Math.floor(remainingSecs / 24 / 60 / 60);
    remainingSecs -= (days * 24 * 60 * 60);
    const hours = Math.floor(remainingSecs / 60 / 60);
    remainingSecs -= (hours * 60 * 60);
    const mins = Math.floor(remainingSecs / 60);
    remainingSecs -= (mins * 60);

    const fragments: string[] = [];
    days > 0 && fragments.push(`${days} day${days > 1 ? "s" : ""}`);
    hours > 0 && fragments.push(`${hours} hour${hours > 1 ? "s" : ""}`);
    mins > 0 && fragments.push(`${mins} minute${mins > 1 ? "s" : ""}`);
    remainingSecs > 0 && !fragments.length && fragments.push(`${remainingSecs} second${remainingSecs > 1 ? "s" : ""}`);

    this.isChristmas = isChristmas;
    this.countdownString = `${fragments.length > 1 ? fragments.slice(0, fragments.length - 1).join(', ') : fragments[0] || "Fuck knows how long"}${fragments.length - 1 > 0 ? " and " + fragments[fragments.length - 1] : ""} until Neknom ${upcomingYear}${isChristmas ? " is over!" : "!"}`;
  }

  closeModal() {
    this.isOpen = false;
    this.videoUrl = "";
    this.router.navigate(['.'], { relativeTo: this.route });
  }

  isUploadDisabled() {
    const isChristmas = new Date().getDate() === this.christmasDate && new Date().getMonth() === 11;
    const hasUploaded = !!this.selfInfo?.completed;
    return !isChristmas || hasUploaded;
  }

  public handleKeyboardEvent($event: KeyboardEvent, isPressed: boolean) {
    if ($event.key === "Control") {
      this.ctrlPressed = isPressed;
    }
  }

  public copyUrl() {
    const pending = this.clipboard.beginCopy(window.location.href);
    let remainingAttempts = 3;
    const attempt = () => {
      const result = pending.copy();
      if (!result && --remainingAttempts) {
        setTimeout(attempt);
      } else {
        // Remember to destroy when you're done!
        pending.destroy();
        this.snackbar.open("URL copied to clipboard!", "DISMISS", { duration: 3000, verticalPosition: "top" });
        console.log("copied");
      }
    };
    attempt();
  }

  public shareVideo() {
    if (!navigator.share) {
      this.snackbar.open("Share not available!", "DISMISS", { duration: 3000, verticalPosition: "top" });
      return;
    }
    if (!this.currentParams['v'] || this.currentParams['v'].split('-').length !== 2) {
      this.snackbar.open("Error sharing video - invalid URL!", "DISMISS", { duration: 3000, verticalPosition: "top" });
      return;
    }
    const [id, year] = this.currentParams['v'].split('-');
    const user = this.users.find((user) => user.id === id);
    if (!user) {
      this.snackbar.open("Error sharing video - unknown user!", "DISMISS", { duration: 3000, verticalPosition: "top" });
      return;
    }
    const title = `Christmas Neknom - ${user.display} ${year}`;
    const text = `Watch ${user.display}'s Neknom ${year} video:\n\n`;

    navigator.share({
      title,
      text,
      url: window.location.href
    }).then(() => console.log("Share complete"))
    .catch((err) => {
      console.warn(err);
    });
  }

  async logout() {
    await Auth.signOut();
    await this.router.navigate(['/login']);
  }
}

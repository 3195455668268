import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent implements OnInit {

  public title = "";
  public type: "success" | "error" | "info";
  public message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { type: "success" | "error" | "info", message: string }
  ) {
    this.type = data.type || "info";
    this.message = data.message || "Unknown error";
    switch(this.type) {
      case "success":
        this.title = "Success!";
        break;

      case "error":
        this.title = "Error!";
        break;
      
      default:
        this.title = "Alert!"
    }
  }

  ngOnInit(): void {
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-confirm-modal',
  templateUrl: './confirm-modal.component.html',
  styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent implements OnInit {

  public title = "";
  public message: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { title: string, message: string },
    private dialogRef: MatDialogRef<ConfirmModalComponent>
  ) {
    this.title = data.title || "Are you sure?";
    this.message = data.message || "Click yes to continue";
  }

  confirm() {
    this.dialogRef.close(true);
  }

  ngOnInit(): void {
  }

}

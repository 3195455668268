import { Injectable } from '@angular/core';
import { Auth } from 'aws-amplify';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private _router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return Auth.currentAuthenticatedUser().then(() => {
      return true;
    }).catch(() => {
      this._router.navigate(['/login'], { queryParams: route.queryParams });
      return false;
    });
  }
}

<div class="full-height" *ngIf="isLoading">
    <div class="spinner-container">
        <mat-spinner class="spinner-center"></mat-spinner>
    </div>
</div>

<div class="full-height" *ngIf="!isLoading">
    <div class="center-box" *ngIf="currentState === 'LOGIN'" (keyup.enter)="login()">
        <div class="section-title">Log In</div>
        <div class="form-layout">
            <mat-form-field>
                <input matInput type="text" placeholder="Email" [(ngModel)]="email"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="password" placeholder="Password" [(ngModel)]="loginPassword"/>
            </mat-form-field>
        </div>
        <p class="click-text" (click)="changeLoginState('FORGOT_PASSWORD')">I forgot my password</p>
        <button mat-raised-button class="action-button" (click)="login()" [disabled]="!email || !loginPassword">Log in</button>
        <p class="error-message" *ngIf="errorMessage !== ''">{{errorMessage}}</p>
    </div>

    <div class="center-box" *ngIf="currentState === 'FORGOT_PASSWORD'" (keyup.enter)="sendAuthCode()">
        <div class="section-title">Forgot Password</div>
        <div class="form-layout">
            <mat-form-field>
                <input matInput type="text" placeholder="Email" [(ngModel)]="email"/>
            </mat-form-field>
        </div>
        <p class="click-text" (click)="changeLoginState('LOGIN')">Back to log in</p>
        <button mat-raised-button class="action-button" (click)="sendAuthCode()" [disabled]="!email">Send Verification Code</button>
        <p class="error-message" *ngIf="errorMessage !== ''">{{errorMessage}}</p>
    </div>

    <div class="center-box" *ngIf="currentState === 'CHANGE_PASSWORD'" (keyup.enter)="resetPassword()">
        <div class="section-title">Set New Password</div>
        <div class="form-layout">
            <mat-form-field>
                <input matInput type="text" placeholder="Verification Code" [(ngModel)]="verificationCode"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="password" placeholder="New Password" [(ngModel)]="newPassword1"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="password" placeholder="Retype New Password" [(ngModel)]="newPassword2"/>
            </mat-form-field>
        </div>
        <p class="click-text" (click)="sendAuthCode()">Resend verification code</p>
        <button mat-raised-button class="action-button" (click)="resetPassword()" [disabled]="!verificationCode || !newPassword1 || !newPassword2">Reset Password</button>
        <p class="error-message" *ngIf="errorMessage !== ''">{{errorMessage}}</p>
    </div>

    <div class="center-box" *ngIf="currentState === 'SET_PASSWORD'" (keyup.enter)="setNewPassword()">
        <div class="section-title">Set New Password</div>
        <div class="form-layout">
            <mat-form-field>
                <input matInput type="password" placeholder="Current Password" [(ngModel)]="loginPassword"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="password" placeholder="New Password" [(ngModel)]="newPassword1"/>
            </mat-form-field>
            <mat-form-field>
                <input matInput type="password" placeholder="Retype New Password" [(ngModel)]="newPassword2"/>
            </mat-form-field>
        </div>
        <button mat-raised-button class="action-button" (click)="setNewPassword()" [disabled]="!loginPassword || !newPassword1 || !newPassword2">Set Password</button>
        <p class="error-message" *ngIf="errorMessage !== ''">{{errorMessage}}</p>
    </div>
</div>
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import Amplify, { Auth } from 'aws-amplify';

console.log("Before configuring amplify");
Amplify.configure(environment.amplifyConfig);

// You can get the current config object
Auth.configure();

if (environment.production) {
  console.log("Enabling production mode");
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
